import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import ReCAPTCHA from 'react-google-recaptcha';

class Header extends React.Component {
  render() {
    return (
      <div className="Header">
        <div className="container pt-3 pb-5">
          <div className="row">
            <div className="col-sm-12 text-center">
                <h3 className="logo"><span className="text-yellow ">launch</span> digital</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class HomeHero extends React.Component {
  render() {
    return (
      <div className="HomeHero">
        <div className="row">
          <div className="col-sm pt-4">
            <h1>hello</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <p>
              At <span className="text-yellow">launch</span> digital, we help your ideas take off. Get in touch to see how we can collaborate.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

class HomeContactForm extends React.Component {

  constructor(props) {
      super(props);
      this.validator = new SimpleReactValidator();
      this.state = { name: '', email: '', idea: '', showInfo: false, submitSuccess: false };
    }

    handleChange = (event) => {
      this.setState({[event.target.name]: event.target.value});

      if (this.state.idea.length > 1) {
        this.setState({
          showInfo: true
        });
      }
    }

    onChange = (result) => {

      this.setState({
          verified: true,
          reCaptchaResponse: result,
        }) 

    };

    handleSubmit = (event) => {

      if (this.validator.allValid()) {

        if (this.state.verified) {

          fetch('https://2j9h7sehok.execute-api.eu-west-2.amazonaws.com/prod/ContactFormLambda', {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            body: JSON.stringify(this.state)
          }).then(function(response) {
            //console.log(response);
          }).then(HomeContactForm => {
            this.setState({ submitSuccess: true, showInfo: false});
            //console.log('name: ' + this.state.name);

            Array.from(document.querySelectorAll("input")).forEach(
              input => (input.value = "")
            );
            this.setState({
              itemvalues: [{}]
            });

            Array.from(document.querySelectorAll("textarea")).forEach(
              textarea => (textarea.value = "")
            );
            this.setState({
              itemvalues: [{}]
            });


          });



          event.preventDefault();

        } else {
          event.preventDefault();
          alert ("Please let us know that you're not a robot!");
        }

      } else {

        event.preventDefault();
        this.validator.showMessages();
        // rerender to show messages for the first time
        // you can use the autoForceUpdate option to do this automatically`
        this.forceUpdate();

      }
          
  }// End handle submit

  render() {
    return (
      <div className="HomeContactForm">
        <form id="contactForm" name="contactForm" onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="col-sm">
              <input type="text" className="form-control" value={this.state.value} name="name" onChange={this.handleChange}  placeholder="Your Name" />
              {this.validator.message('name', this.state.name, 'required|alpha_space')}
            </div>
          </div>

          <div className="form-row">
            <div className="col-sm mt-3">
              <input type="text" className="form-control"  value={this.state.value} name="email" onChange={this.handleChange}  placeholder="Your Email" />
              {this.validator.message('email', this.state.email, 'required|email')}
            </div>
          </div>

          <div className="form-row mt-3">
            <div className="col">
              <textarea rows="5" className="form-control" value={this.state.value} name="idea" onChange={this.handleChange} placeholder="Your Idea"></textarea>
              {this.validator.message('idea', this.state.idea, 'required|alpha_num_dash_space')}
            </div> 
          </div>
            <div className="form-row mt-3" style={{ display: this.state.showInfo ? "block" : "none" }}>
                      <div className="col">
                      <ReCAPTCHA
                      sitekey="6Le9WnkcAAAAABjsaIzqEC7WJ9fb_txLsqpxUrSr"
                      theme="dark"
                      onChange={this.onChange}
                    />
            </div> 
          </div>
          <div className="form-row mt-2">
            <div className="col">
              <button type="submit" name="submit" className="btn mt-2 px-4">submit</button>
            </div> 
          </div>
          <div className="form-row" style={{ display: this.state.submitSuccess ? "block" : "none" }}>
            <div className="col-sm mt-3">
              <p>Thanks for your enquiry. We'll be in touch shortly!</p>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

class HomeTwoColumn extends React.Component {
  render() {
    return (
      <div className="HomeTwoColumn">
        <div className="container mt-5">
          <div className="row">
            <div className="col-sm-6 p-4">
              <HomeHero />
            </div>
            <div className="col-sm-6 p-4">
              <HomeContactForm />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class App extends React.Component {
  render() {
    return (
    <div className="container">
      <Header />
      <HomeTwoColumn />
    </div>
    );
  }
}

export default App;
